.nav-tabs .nav-link {
    background-color: transparent !important;
    color: rgb(8,114,182) !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
  }
  
.nav-tabs .nav-link.active {
  border-bottom: solid 4px rgb(0,177,168) !important;
  color: rgb(0,177,168) !important;
}

#contained-modal-title-vcenter{
  color:rgb(78,79,83);
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.modal-text{
  color:rgb(78,79,83);
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  margin: 0;
}