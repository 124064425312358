#Water-bar .progress-bar {
    background-color: rgb(213,43,0)!important;
}

#Electricity-bar .progress-bar {
    background-color: rgb(8,114,182)!important;
}

#Thermal-bar .progress-bar {
    background-color: rgb(0,177,168)!important;
}

#Other-bar .progress-bar {
    background-color: rgb(78,79,83)!important;
}

.hide-scrollbar::-webkit-scrollbar {
    display: none;
}

.hide-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;
}

.projects-header {
	.tabletitle {
		display: inline-block;
        margin: 0 10px 0 0;
	}

	.count {
		display: inline-block;
		color: #738297;
        margin: 0;
	}
}