.es-breadcrumb{
    clear: both;
    position: relative;
}
.es-breadcrumb li{
    list-style: none;
    float: left;
    padding-right: 10px;
}
.funcionalidades.container {
    width: 90%;
    margin: auto;
}
.es-breadcrumb ul.breadcrumb{
    background-color: rgb(0,177,168);
    padding: 0 1.5em;
    clear: both;
    height: 40px;
}
.es-breadcrumb ul.breadcrumb li{  
    background-color: rgb(8,114,182); 
    border-right: 3px solid #fff;
    transform: skewX(-25deg);
    height: 40px;
    padding: 0 20px;
    position: relative;
    top: 0; 
    line-height: 40px;
}
.es-breadcrumb ul.breadcrumb li:first-child {
    display: none;
}
.es-breadcrumb ul.breadcrumb li:nth-child(2) {
    border-left: 3px solid #fff;
}
.es-breadcrumb ul.breadcrumb li:last-child {
    border-right: none;
}
.es-breadcrumb ul.breadcrumb li a{	
    color: rgb(229, 226, 226);
    transform: skewX(25deg);
    display: inline-block;
}
.es-breadcrumb ul.breadcrumb li a:hover, .es-breadcrumb ul.breadcrumb li a:focus{
    text-decoration: underline;
    color: #fff;
    cursor: pointer;
}
.es-breadcrumb ul.breadcrumb li strong {
    color: #fff;
    text-decoration: none;
    transform: skewX(25deg);
    display: inline-block;
    font-size: 1.25rem;
    font-weight: 400;
    text-transform: uppercase;
}
.es-breadcrumb ul.breadcrumb i{
    position: relative;
    top: 6px;
    float: none;
}