

#list-container{
    text-align: center;
    padding: 1vh 1vw;
    height: 80vh;
    width: 40vw;
    background-color: rgba(168, 168, 168, 0.25);
    border-radius: 2%;
    margin-top: 10vh;
    margin-left: 30vw;
}

.info-box{
    overflow-x: hidden;
    /* shadow and dims */
    -webkit-box-shadow: 0px 12px 6px -6px rgba(41,41,41,0.25);
    -moz-box-shadow: 0px 12px 6px -6px rgba(41,41,41,0.25);
    box-shadow: 0px 12px 6px -6px rgba(41,41,41,0.25);  padding: 0;
    width: 100%;
    height: 16%;
    /* background-color: aqua; */
    background-color: whitesmoke;
    margin-bottom: 2%;
    overflow: visible;
    display: flex;
    flex-direction: row;
}

.info-box-header{
    margin-top: 1vh;
}

.info-box-text{
    margin:0;
    font-size: 0.75rem;
}

#ib1{
    /* padding-bottom: 5%; */
    
}
#ib2, #ib4{
    /* margin-left: 25%; */
}
#ib3{

}
#ib4{

}

#info-header{
    font-weight: 700;
    letter-spacing: 2px;
}

#test{
    -webkit-transform: skew(20deg);
    -moz-transform: skew(20deg);
    -o-transform: skew(20deg);
    transform: skew(20deg);
    /* width: 7%;
    height: 15%; */
    /* margin-top: 11%; */
    /* background-color: blue; */
    /* position: absolute; */
    height: 115%;
    width: 8vw;
}

