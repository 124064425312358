.metrics-slide-container{
    width: 100%;
    height:100%;
    padding: 1vh 1vw;
    background-image: url("../../../../media/tempBackup.jpeg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.metrics-slide-row-bottom, .metrics-slide-row-top{
    width: 100%;
    display: flex;
    flex-direction: row;
}

.metrics-slide-row-top{
    height: 59%;
}

.metrics-slide-row-md{
    height: 2%;
}

.metrics-slide-row-bottom{
    height: 39%;
}

.metrics-slide-top-lf{
    flex:3;
}

.metrics-slide-top-lf-1{
    height:39%;
    padding: 2%;
    border-radius:2%;
    box-shadow: 2px 6px 10px rgb(164, 164, 164);
    background-color: rgba(255,255,255, 0.7);
}

.metrics-slide-top-lf-2{
    height:2%;
}

.metrics-slide-top-lf-3{
    height:59%;
    padding: 1.75%;
    border-radius:2%;
    box-shadow: 2px 6px 10px rgb(164, 164, 164);
    background-color: rgba(255,255,255, 0.7);
}

.metrics-slide-top-md{
    flex:3;
    padding: 1%;
    border-radius:2%;
    box-shadow: 2px 6px 10px rgb(164, 164, 164);
    background-color: rgba(255,255,255, 0.7);
}

.metrics-slide-top-rt{
    flex:5;
    padding: 1%;
    border-radius:2%;
    box-shadow: 2px 6px 10px rgb(164, 164, 164);
    background-color: rgba(255,255,255, 0.7);
}

.metrics-slide-bottom-lf{
    flex:1;
    padding:5px 1% 1% 1%;
    border-radius:2%;
    box-shadow: 2px 6px 10px rgb(164, 164, 164);
    background-color: rgba(255,255,255, 0.7);
}

.metrics-slide-bottom-rt{
    flex:1;
    padding:5px 1% 1% 1%;
    border-radius:2%;
    box-shadow: 1px 2px 6px rgb(164, 164, 164);
    background-color: rgba(255,255,255, 0.7);
}

.gauge{
    font-family: Arial, Helvetica, sans-serif;
    background-color: red;
    background-image: linear-gradient(to right , red , orange , yellow , green);
    box-shadow: 0 0 0 6px rgba(255, 255, 255, 0.09), 0 0 35px 5px rgba(255, 255, 255, 0.29);
    width: 35%;
    height: 40%;
    border-radius: 100px 100px 0 0;
    position: relative;
    overflow: hidden;
    /* safari fix */
    -webkit-transform-style: flat;
    transform-style: flat;
    -webkit-transform: translateZ(0px);
    transform: translateZ(0px);
    margin: auto;
}

.gauge-center {
    content: '';
    color: #fff;
    width: 65%;
    height: 65%;
    background: #15222E;
    border-radius: 100px 100px 0 0!important;
    position: absolute;
    box-shadow: 0 -13px 15px -10px rgba(0, 0, 0, 0.28);
    right: 17.25%;
    bottom: 0;
    color: #fff;
    z-index:10;
  }
  
  .gauge-center .label, .gauge-center .number {display:block; width: 100%; text-align: center; border:0!important;}
  .gauge-center .label {font-size:0.89rem; opacity:0.6; margin:1.1em 0 0.3em 0;}
  .gauge-center .number {font-size:1.1em;}

  .needle {
    width: 80px;
    height: 10%;
    background: #15222E;
    border-bottom-left-radius: 100%!important;
    border-bottom-right-radius: 5%!important;
    border-top-left-radius: 100%!important;
    border-top-right-radius: 5%!important;
    position: absolute;
    bottom: -2px;
    left: 10%;
    transform-origin: 100% 2%;
    transform: rotate(0deg);
    box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.38);
    display:block;
    z-index:9;
  }
  
  .needle {animation: fourspeed1 1s 1 both; animation-delay: 0.25s; display:block;}

  @-webkit-keyframes fourspeed1 {
	0% {transform: rotate(0);}
  100% {transform: rotate(42deg);}
}

@keyframes fourspeed1 {
	0% {transform: rotate(0);}
  100% {transform: rotate(42deg);}
}

.swsv-metrics-slide{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 1.45rem;
    color:rgb(78,79,83);
    text-align: center;
}

.swsv-metrics-slide-span{
    font-size: 1em;
}