.x-axis .recharts-cartesian-axis-tick:first-of-type {
    display: none;
}
.y-axis .recharts-cartesian-axis-tick:last-of-type {
    display: none;
}

#raw-data-graph-error-msg-container{
    background-color: white;
    height: 98%; 
    width: 100%;
    display: flex;
    flex-direction: column; 
    align-items: center; 
    justify-content: center;
    padding: 0 2%;
    border-radius: 10px;
    box-shadow: 2px 6px 12px rgb(164, 164, 164);
}