.flip-card, .flip-card-flip {
    background-color: transparent;
    width: 20vw;
    height: 16vw;
    perspective: 1000px;
    margin: 0.75% 1%;
  }
  
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.7s;
    transform-style: preserve-3d;
  }
  
  .flip-card-flip .flip-card-inner {
    transform: rotateY(180deg);
  }
  
  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height:100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    text-align: left;
    border-radius: 4%;
    /* padding: 0.5vw; */
    box-shadow: 2px 6px 12px rgb(164, 164, 164);
    /* overflow-y: scroll; */
  }

  /* .flip-card-front::-webkit-scrollbar, .flip-card-back::-webkit-scrollbar{
    width: 4px;
  }

  .flip-card-front::-moz-scrollbar, .flip-card-back::-moz-scrollbar{
    width: 4px;
  }

  .flip-card-front::-ms-scrollbar, .flip-card-back::-ms-scrollbar{
    width: 4px;
  }

  .flip-card-front::-webkit-scrollbar-thumb, .flip-card-back::-webkit-scrollbar-thumb{
    border-radius: 4%;
    background-color:rgb(185, 185, 185);
  }

  .flip-card-front::-moz-scrollbar-thumb, .flip-card-back::-moz-scrollbar-thumb{
    border-radius: 4%;
    background-color:rgb(185, 185, 185);
  }

  .flip-card-front::-ms-scrollbar-thumb, .flip-card-back::-ms-scrollbar-thumb{
    border-radius: 4%;
    background-color:rgb(185, 185, 185);
  }
   */
  .flip-card-front {
    /* background-color: white; */
    color: black;
  }
  
  .flip-card-back {
    /* background-color: white; */
    color: black;
    transform: rotateY(180deg);
  }

  h4, h6, p{
    margin: 0;
  }

  .opp-footer-options{
    color: rgb(78,79,83);
}

.opp-footer-options:hover{
    cursor: pointer;
    text-decoration: underline;
    color: rgb(8,114,182);
}

.rmis-card-text{
    color: rgb(78,79,83);
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    margin: 0;
}

.opp-card-toggle{
  color: rgb(78,79,83);
}

.opp-card-toggle:hover{
  color:rgb(8,114,182);
  cursor: pointer;
}

.opp-text-main{
  color: rgb(78,79,83);
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  margin-bottom:0.2rem;
  font-size: 0.9rem;
}

.opp-warning{
  color: red
}

.opp-success{
  color: green;
}

.opp-success, .opp-warning{
  font-size: 0.9rem;
  margin: 0;
}