#overview-cards{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 2%;
}

#overview-trends, #misc-data{
    display: flex;
    flex-direction: row;
}

#opp-data{
    display: flex;
    flex-direction: row;
    box-shadow: 2px 6px 12px rgb(164, 164, 164);
    width: 98%;
    margin: auto;
    margin-bottom: 2%;
}

.summary-trend{
    height:50vh;
    flex:1;
    margin: 0 1%;
    border-radius: 2%;
    padding:1%;
    /* background-color: rgb(249,249,249); */
    background-color: white;
    box-shadow: 2px 6px 12px rgb(164, 164, 164);
    margin-bottom: 2%;
}

#nav-ctr{
    background-color:#f5f5f5;
    /* background-color: rgb(226,227,232); */
}

#elec-pareto{
    height:50vh;
    flex:7;
    margin: 0 1%;
    border-radius: 2%;
    padding:1% 1% 1% 1%;
    /* background-color: rgb(249,249,249); */
    background-color: white;
    box-shadow: 2px 6px 12px rgb(164, 164, 164);
}

#quick-summary{
    height: 50vh;
    flex:3;
    margin: 0 1%;
    margin-bottom: 1%;
}

#site-card, #opp-card{
    height:48%;
    background-color: white;
    border-radius: 2%;
    box-shadow: 2px 6px 12px rgb(164, 164, 164);
    padding:2%;
}

#summary-buffer{
    height: 4%;
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
}