#collasible-nav-dropdown::after {
    content: none;
}

.nav-icon{
    font-size: 30px !important;
}

.nav-icon:hover{
    color:rgb(8,114,182);
}

.navbar,
.navbar-default {
    background-image: linear-gradient(to bottom,rgb(255,255,255) 0,rgb(255,255,255) 100%) !important;
}