/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(78,79,83);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(8,114,182);
}

/* COLORS */
:root {
  --es-c1: #0872B6;
  --es-c2: #00B1A8;
}


#light {
  background-color: white;
}

#dark {
  background-color: #202121;
}

#light .navba

#light .siteCard {
  background-color: white;
}

#dark .siteCard {
  background-color: #181818;
  color: white;
}

#dark .primary {
  color: white;
}

#dark .secondary {
  color: #d3d3d3;
}

.rmis-userag-modal{
  background-color: #0872b6;
  background-image: linear-gradient(to top, rgb(8,114,182) 0%, rgba(8,114,182, 0.7) 1%, rgba(79, 156, 203, 0.7) 26%, rgba(131, 183, 214, 0.6)48%, rgba(49, 140, 196, 0.75) 80%, rgb(8,114,182) 100%);
}