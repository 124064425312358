.metric-span{
    color:rgb(78,79,83);
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 3vh;
}

.metric-count{
    color:rgb(8,114,182);
    font-size: 5vh;
}

h4{
    color:rgb(78,79,83);
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.summary-card{
    min-height:25vh;
    height:fit-content;
    flex:1;
    margin: 0 1%;
    border-radius: 2%;
    padding:1%;
    background-color: white;
    box-shadow: 2px 6px 12px rgb(164, 164, 164);
}

.ghg-legend{
    margin-top: 5px;
}

.ghg-legend, .ghg-param {
    display: flex;
    flex-wrap: wrap;
}

.param-color {
    height:1vh;
    width: 1vh;
    border-radius: 1vh;
    display: table-cell;
    margin-top: 0.8vh;
    margin-right: 0.8vh;
}

.param-title{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    margin-right: 1vh;
    color: rgb(78,79,83);
}

.metrics-bar .progress-bar {
    background-color: rgb(8,114,182)!important;
}

.card-info-lg, .card-info-sm{
    color: rgb(78,79,83);
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    margin: 0;
}

.card-info-lg{
    margin-top: 1%;
    font-size: 110%;
}

.card-info-sm {
    font-size: 90%;
}

#emissions-cutsheet-text{
    color: rgb(78,79,83);
}

#emissions-cutsheet-text:hover{
    cursor: pointer;
    text-decoration: underline;
    color: rgb(8,114,182);
}

.card-name{
    display: inline-block;
}

.card-toggle{
    float: right;
    color: rgb(78,79,83);
}

.card-toggle:hover{
    color:rgb(8,114,182);
    cursor: pointer;
}

