@keyframes spin {
    from {
        transform: rotateY(0deg);
    }
    to {
        transform: rotateY(360deg);
    }
}

#mobile-landing-container {
    background: rgb(234,242,255);
    background: radial-gradient(circle, rgba(234,242,255,1) 0%, rgba(169,209,255,0.7637648809523809) 100%);
    height: 100vh;
    width: 100vw;
    perspective: 1000px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

#mobile-landing-logo {
    animation: spin 10s linear infinite;
    height: 25vh;
    width: auto;
    transform-style: preserve-3d;
}