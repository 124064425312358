
#landingbackground{
    background-image: url("../../media/landing_bg1.png");
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

#auth-box{
    height: fit-content;
    width: fit-content;
    min-height: 35vh;
    min-width: 20vw;
    background-image: radial-gradient(circle 248px at center, rgba(249,249,249,1) 0%, rgba(226, 224, 224, 0.95)  47%, rgba(222, 219, 219, 0.9) 100%);
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    border-radius: 5%;
    padding: 2% 0 2% 0;
    border: 2px solid rgb(8,114,182)
}

h3, p{
    font-family: Verdana;
}

#pass-reset{
    color:rgb(78,79,83);
}

#pass-reset:hover{
    text-decoration: underline;
    cursor: pointer;
    color:black;
}

#landing-contact:hover{
    text-decoration: underline;
}

#auth-ribbon{
    height:2vh;
    width: 100%;
}